.ShareButtons_wrap {
  max-width: var(--text-width);
  display: flex;
  justify-content: space-between;
  margin: 0 auto 40px;
}

.ShareButtons_facebook,
.ShareButtons_twitter,
.ShareButtons_linkedin {
  flex: 0 0 180px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
  height: 30px;
  line-height: 30px;
  text-align: center;
  text-transform: uppercase;
  color: white;
}

@media all and (max-width: 1024px) {
  .ShareButtons_facebook {
    flex: 1 1 134px;
    margin-right: 15px;
  }

  .ShareButtons_twitter {
    flex: 1 1 70px;
    margin-right: 15px;
  }

  .ShareButtons_linkedin {
    flex: 1 1 70px;
  }
}

.ShareButtons_facebookLogo,
.ShareButtons_twitterLogo,
.ShareButtons_linkedinLogo {
  position: relative;
}

.ShareButtons_facebookLogo::before,
.ShareButtons_twitterLogo::before,
.ShareButtons_linkedinLogo::before {
  font-family: "Font Awesome 5 Brands";
  font-weight: 500;
  font-size: 20px;
  margin-right: 15px;
  vertical-align: sub;
}

.ShareButtons_facebook {
  background-color: #3b5998;
}

.ShareButtons_facebook:hover,
.ShareButtons_facebook:focus {
  background-color: #4f74c1;
  text-decoration: none;
}

.ShareButtons_facebook:active {
  background-color: #2f4678;
}

.ShareButtons_facebookLogo::before {
  content: "\f09a";
}

.ShareButtons_twitter {
  background-color: #00aced;
}

.ShareButtons_twitter:hover,
.ShareButtons_twitter:focus {
  background-color: #30c6ff;
  text-decoration: none;
}

.ShareButtons_twitter:active {
  background-color: #048fc3;
}

.ShareButtons_twitterLogo::before {
  content: "\f081";
}

.ShareButtons_linkedin {
  background-color: #0077b5;
}

.ShareButtons_linkedin:hover,
.ShareButtons_linkedin:focus {
  background-color: #0899e5;
  text-decoration: none;
}

.ShareButtons_linkedin:active {
  background-color: #025b8a;
}

.ShareButtons_linkedinLogo::before {
  content: "\f08c";
}
